import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero/hero"
import RightCta from "../../components/cta/right-bordered/cta"
import LeftCta from "../../components/cta/left-bordered/cta"
import Quiz from "../../components/quiz/quiz.js"
import Savings from "../../components/savings/savings";

import desktopHero from "../../images/quiz/desktopHero.png"
import mobileHero from "../../images/quiz/mobileHero.png"




export default () => (

  <Layout>
    <SEO
      title="Taken our Cardiovascular Risk Quiz"
      keywords="cardiovascular quiz, health assessment cardiovascular system quiz"
      description="Is your heart more at risk than you think? Take the Cardiovascular (CV) Risk Quiz and see how much you know. Please see Indication and Important Safety Information."
       />


      <Hero
        desktopHeroBackground={desktopHero}
        mobileHeroBackground={mobileHero}
        alt="Woman smiling"
        headline={["Is your heart more at risk than you think?"]}
        content={[ <span style={{marginTop: "15px", display:"inline-block"}}>Statins, diet, and exercise can reduce your cardiovascular (CV) risk. But that risk is often persistent, and you may not be as safe as you think.</span>,<span className="animationC" style={{marginTop: "15px", display:"inline-block"}}><span style={{fontWeight:700}}>Take the CV Risk Quiz below and see how much you know</span></span>]}/>


    <div className="block  row">
    	<div style={{background: "#098ab4"}} className="col-md-12 block-content ">
    		<div style={{minHeight:"160px"}} class="block-headline gradient-alt1 justify-content-center">
          <h3 style={{whiteSpace:"nowrap", paddingBottom:"0px !important"}} className="mobile-18 my-auto">{["The Cardiovascular",<br className = "desktop-show-none"/>," Risk Quiz"]}</h3>
    		</div>
    	</div>

    </div>

    <Quiz/>





  </Layout>
)

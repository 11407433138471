import React from "react"
import { Link } from "gatsby"
import "./quizStyles.css"

import drop from "../../images/quiz/drop.svg"
import fishIcon from "../../images/quiz/fishIcon.svg"
import heartIcon from "../../images/quiz/1.1_Heart_Icon-01.png"
import questionIcon from "../../images/quiz/questionIcon.svg"
import apple from "../../images/quiz/apple.png"
import dollar from "../../images/quiz/dollar.png"


var questions = [
  {
    question:
      "Statins reduce your chance of suffering a CV event by up to 90%.",
    answer: [""],
    answerval: "",
    icon: heartIcon,
    incorrect: [
      "Sorry, this is FALSE. Here’s the truth: statins, diet, and exercise can lower your risk by about 25%-35%, which isn’t quite 90%, but is still a significant reduction. However, the CV risk that remains, even if you’re taking a statin and managing CV risk factors like high blood pressure, diabetes, high cholesterol, and raised triglycerides, is what we call ",
      <strong>Persistent Cardiovascular Risk (P-CVR).</strong>,
      " P-CVR is defined as the persistent CV risk that may remain even if you are taking a conventional therapy like a statin. VASCEPA provides 25% additional protection from P-CVR on top of what you get by taking a statin. In a clinical study, patients treated with VASCEPA had fewer CV events (17.2%) compared to those who took placebo (22%). Ask your doctor about your individual risk.",
    ],
    correct: [
      "That’s right! Nice job. Statins, along with diet and exercise, can lower your risk, but not by 90%. The real number is closer to 25%-35%, which is still significant.  However, the CV risk that remains, even if you’re taking a statin and managing CV risk factors like high blood pressure, diabetes, high cholesterol, and raised triglycerides, is what we call ",
      <strong>Persistent Cardiovascular Risk (P-CVR).</strong>,
      " P-CVR is defined as the persistent CV risk that may remain even if you are taking a conventional therapy like a statin. VASCEPA provides 25% additional protection from P-CVR on top of what you get by taking a statin. In a clinical study, patients treated with VASCEPA had fewer CV events (17.2%) compared to those who took placebo (22%). Ask your doctor about your individual risk.",
    ],
    nextQuestion: ["Next question ", <span aria-hidden="true">&gt;</span>],
  },

  
  {
    question:
      "Having a first CV event, such as a heart attack or stroke, puts you at greater risk to suffer another.",
    answer:
      "Having a CV event makes you more likely to suffer another. That’s why it's so important to protect against a first CV event or future CV events. As you navigate your way through these difficult times, it’s important to closely monitor your overall health. Stay in close contact with your doctor and do all you can to reduce your CV risk by keeping up with your medications, exercising, and sticking to a healthy diet.",
    answerval: true,
    icon: questionIcon,
    incorrect: "Unfortunately, this is TRUE. ",
    correct: "Correct, this is TRUE. ",
    nextQuestion: ["Next question ", <span aria-hidden="true">&gt;</span>],
  },
  {
    question:
      "Fish oil supplements are a proven way to get protection from a CV event.",
    answer: [
      "Fish oil supplements are not FDA-approved medicines and are classified as food. They are not intended for patients to treat or prevent a medical condition. These products have failed in CV trials to reduce risk on top of current medical therapies including statins. You can see for yourself—look for an asterisk on a fish oil supplement bottle and read the fine print.",
    ],
    answerval: false,
    icon: fishIcon,
    incorrect: "Sorry, this is FALSE. ",
    correct: "Nothing fishy gets past you. ",
    nextQuestion: ["Next question ", <span aria-hidden="true">&gt;</span>],
  },
  
  {
    question: [
      "You can get the same CV risk reduction from other cholesterol-lowering medications in addition to statins as you can from VASCEPA.",
    ],
    answer: [
      "VASCEPA was not tested head-to-head against cholesterol-lowering medications, but VASCEPA is the only medication approved to reduce the risk of heart attack, stroke, and other heart issues requiring hospitalization in adults with elevated triglycerides and with heart disease, or diabetes and 2 or more additional risk factors for heart disease when taken along with statins. VASCEPA is not a low-density lipoprotein cholesterol (LDL-C) management medication. VASCEPA is for lowering the risk of heart attacks, strokes and other serious CV events in patients with Persistent Cardiovascular Risk (P-CVR). P-CVR exists beyond cholesterol management and beyond standard-of-care therapies for hypertension, diabetes, and other CV-related risks.",
    ],
    answerval: false,
    icon: heartIcon,
    incorrect: "Sorry this is FALSE. ",
    correct: "You got it, correct. ",
    nextQuestion: ["Next question ", <span aria-hidden="true">&gt;</span>],
  },
  
  
  {
    question:
      "There’s no need to worry about CV disease if I’m younger, or eating right, exercising, and aiming to reach weight goals suggested by my doctor. ",
    answer:
      "CV disease can impact anyone. Eating right, staying active, and aiming to reach your weight goals are recommended steps to reduce your risk, but we encourage you to talk to your doctor about your overall health. Even if you feel fit and healthy, lurking CV risk factors like your family history and your cholesterol levels can increase your CV risk, as can other risk factors such as diabetes. ",
    answerval: false,
    icon: apple,
    incorrect: "While we all wish this were true, it’s actually FALSE. ",
    correct: "Correct. ",
    nextQuestion: ["Next question ", <span aria-hidden="true">&gt;</span>],
  },
  {
    question:
      "A breakthrough therapy like VASCEPA–the only medication FDA approved for its indication that took over a decade to develop–must be hard to get. ",
    answer:
      "Amarin has pledged to make VASCEPA affordable and available. VASCEPA is covered by most commercial and government insurance plans. While federal law prohibits copay savings programs for patients with government insurance plans, VASCEPA is covered by most such plans. For patients with commercial insurance there is a copay savings program available. Those eligible can lower their copay to $9.00 for a 3-month supply. When you’re done acing this quiz, visit our savings page for all the ways you may be able to save. ",
    answerval: false,
    icon: dollar,
    incorrect: "Sorry, this is FALSE. ",
    correct: "You got it. ",
    nextQuestion: ["Finish ", <span aria-hidden="true">&gt;</span>],
  },
]

export default class Quiz extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      position: "initial",
      currentQuestion: 1,
      selectedAnswer: null,
      currentAnswerState: "",
      error: "none",
      deafen: false,
      correctQuestions: [],
      completed: "none"
    }
  }

  componentDidUpdate() {
    this.handleOuterHeight()
    var scroll = document.getElementsByClassName("quiz-anchor")[0].getBoundingClientRect().top + window.pageYOffset
    window.scrollTo(0,scroll)
  }
  componentDidMount() {
    this.handleOuterHeight()
  }

  handleselect = event => {
    if (document.getElementsByClassName("selected")[0]) {
      document
        .getElementsByClassName("selected")[0]
        .classList.remove("selected")
    }
    event.currentTarget.classList.add("selected")

    if (event.currentTarget.classList.contains("true")) {
      this.setState({
        selectedAnswer: true,
        error: "none",
      })
    } else {
      this.setState({
        selectedAnswer: false,
        error: "none",
      })
    }
  }

  handleKeySelect = event =>{
    if (event.key != "Tab"){
      this.handleselect(event)
    }
  }

  handleSubmit = event => {
    var scroll = document.getElementsByClassName("quiz-anchor")[0].getBoundingClientRect().top + window.pageYOffset
    if (this.state.selectedAnswer != null) {
      var currentCorrect = this.state.correctQuestions
      if (
        this.state.selectedAnswer !=
        questions[this.state.currentQuestion - 1].answerval
      ) {
        this.setState({
          currentAnswerState:
            questions[this.state.currentQuestion - 1].incorrect,
            correctQuestions: [...currentCorrect, 0],

        })
      } else {
        this.setState({
          currentAnswerState: questions[this.state.currentQuestion - 1].correct,
          correctQuestions: [...currentCorrect, 1],
        })
      }

      this.setState({
        position: "answered",
      })
    } else {
      this.setState({
        error: "block",
      })
    }
    // const questionContainer = document.querySelector(".quiz-anchor")
    // questionContainer.scrollIntoView({ behavior: "smooth", block: "start" })
    window.scrollTo(0,scroll)
  }

  handleKeySubmit = event => {
    if (event.key != "Tab"){
      this.handleSubmit(event)
    }
  }

  handleNext = () => {
     
      var scroll = document.getElementsByClassName("quiz-anchor")[0].getBoundingClientRect().top + window.pageYOffset

    if (document.getElementsByClassName("selected")[0] != undefined) {
      // console.log("moving to next question")
      
      document
        .getElementsByClassName("selected")[0]
        .classList.remove("selected")
    }
    if (this.state.currentQuestion === 6) {
      //document.querySelector(".quiz-outer").style.height = "550px"
      document.querySelector(".quiz-outer").classList.add("quiz-final")
      this.setState({
        position: "completed",
        completed: "block"
      })
    } else {
      document.querySelector(".quiz-outer").classList.remove("quiz-final")
      if (this.state.deafen === false) {
        this.setState({
          position: "asked",
          selectedAnswer: null,
          error: "none",
          deafen: true,
        })
        setTimeout(this.nextQuestion, 125)
        setTimeout(this.removeDeafen, 250)
      }
    }
    // const questionContainer = document.querySelector(".quiz-anchor")
    // questionContainer.scrollIntoView({ behavior: "smooth", block: "start" })
    window.scrollTo(0,scroll)
  }

  handleKeyNext = (event) =>{
    if (event.key != "Tab"){
      this.handleNext()
    }
  }

  handleOuterHeight = height => {
    // console.log(height === undefined)
    if (document.getElementsByClassName("active")[0]) {
      var height = window.getComputedStyle(
        document.getElementsByClassName("active")[0]
      ).height
    } else {
      var height = window.getComputedStyle(
        document.getElementsByClassName("initial")[0]
      ).height
    }
    if (window.innerWidth > 1000) {
      document
        .getElementsByClassName("quiz-outer")[0]
        .setAttribute("style", `height:700px`)
    } else {
      document
        .getElementsByClassName("quiz-outer")[0]
        .setAttribute("style", `height:${height}`)
    }
    
  }

  removeDeafen = () => {
    this.setState({
      deafen: false,
    })
  }

  handlePrevious = () => {
    var scroll = document.getElementsByClassName("quiz-anchor")[0].getBoundingClientRect().top + window.pageYOffset

    if (document.getElementsByClassName("selected")[0] != undefined) {
      document
        .getElementsByClassName("selected")[0]
        .classList.remove("selected")
    }
    var correctQuestions = this.state.correctQuestions

    if (this.state.currentQuestion === 1 && this.state.deafen === false) {
      var currentQuestion = this.state.currentQuestion
      this.setState({
        currentQuestion: currentQuestion,
        correctQuestions: [],
        position: "asked",
        selectedAnswer: null,
        currentAnswerState: "",
        error: "none",
        deafen: true,
      })
    } else if (
      this.state.deafen === false &&
      this.state.position === "answered"
    ) {
      this.setState({
        position: "asked",
        correctQuestions: correctQuestions.slice(0,correctQuestions.length - 1),
        selectedAnswer: null,
        currentAnswerState: "",
        error: "none",
        deafen: true,
      })
    } else if (this.state.deafen === false) {
      this.setState({
        position: "asked",
        correctQuestions: correctQuestions.slice(0,correctQuestions.length - 1),

        selectedAnswer: null,
        currentAnswerState: "",
        error: "none",
        deafen: true,
      })
      this.previousQuestion()
    }
    setTimeout(this.removeDeafen, 750)
    
    // const questionContainer = document.querySelector(".quiz-anchor")
    // questionContainer.scrollIntoView({ behavior: "smooth", block: "start" })
    window.scrollTo(0,scroll)

  }

  handleKeyPrevious = (event) => {
    if (event.key != "Tab"){
      this.handlePrevious()
    }
  }

  restartQuiz = () => {
    this.setState({
      position: "asked",
      currentQuestion: 1,
      selectedAnswer: null,
      currentAnswerState: "",
      correctQuestions: [],
      error: "none",
      deafen: false,
    })
    setTimeout(this.removeDeafen, 750)
  }

  restartKeyQuiz = (event) => {
    if (event.key != "Tab"){
      this.restartQuiz()
    }
  }

  previousQuestion = () => {
    var currentQuestion = this.state.currentQuestion

    this.setState({
      currentQuestion: currentQuestion - 1,
    })
  }

  nextQuestion = () => {
    var currentQuestion = this.state.currentQuestion

    this.setState({
      currentQuestion: currentQuestion + 1,
      currentAnswerState: "",
    })
  }

  render() {
    var position = this.state.position
    if (this.state.position === "asked") {
      var question = "active"
    } else if (this.state.position === "initial") {
      var question = "initial"
    } else {
      var question = "inactive"
    }
    var answer = this.state.position === "answered" ? "active" : "inactive"
    var finalScreen =
      this.state.position === "completed" ? "active" : "inactive"


    var finalScreenRender = 
    <div
    className={"block row questionContainer finalScreen " + finalScreen}
    style={{ background: "#E7E8EA",display: this.state.completed }}
  >
    <div style={{ position: "relative", top:"50%"}} className="col-md-12">
      <div className="centered-row row">
        <div className="block-headline  my-auto col-md-12">
          <h2
            style={{
              color: "#ffffff",
              fontSize: "25px",
              margin: "0px 20px 0px 23px",
              color: "#7C4B9D",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            You've gotten {this.state.correctQuestions.reduce((a,b) => a + b,0)} out of 6 correct
          </h2>
          <br />
          <h2
            style={{
              color: "#ffffff",
              fontSize: "25px",
              margin: "0px 20px 0px 23px",
              color: "#7C4B9D",
              fontWeight: "700",
            }}
          >
            That’s it! Now that you know how persistent CV risk can be, ready to take the next step? Download our doctor
            discussion guide to make a heart protection plan with your
            doctor, or visit our savings page to see if you can save on
            VASCEPA.
          </h2>
          <br />
        </div>
        <div className="col-md-4 justify-content-center m-p-0 ">
          <div
            style={{
              marginBottom: "0px",
              marginTop: "10px",
              color: "#ffffff",
            }}
            class=" quizButton"
          >
            <a
              className="link-white"
              target="_blank"
              id="get-heart-healthy-tips"
              href="https://amarincorp.com/docs/Vascepa-Downloadable-DDG-2020.pdf"
            >
              <h2 className="my-auto">Download guide</h2>
            </a>
          </div>
        </div>
        <div className="col-md-4 justify-content-center m-p-0 ">
          <div
            style={{
              marginBottom: "0px",
              marginTop: "10px",
              color: "#ffffff",
            }}
            class=" quizButton"
          >
            <Link
              className="link-white"
              id="get-heart-healthy-tips"
              to="/getting-started/vascepa-savings"
            >
              <h2 className="my-auto">Savings</h2>
            </Link>
          </div>
        </div>

        <div className="col-md-4 justify-content-center m-p-0">
          <div
            tabIndex="0"
            onKeyDown={this.restartKeyQuiz}
            onClick={this.restartQuiz}
            id="take-quiz-again"
            style={{
              marginBottom: "30px",
              marginTop: "10px",
              color: "#ffffff",
            }}
            class="quizButton"
          >
            <h2 className="my-auto">Take quiz again</h2>
          </div>
        </div>
      </div>
    </div>
  </div>

    return (
      <div className="quiz-tag-container">
      <div className="quiz-anchor" style={{height: "1px",width:"100%", opacity:"0",position:"absolute",marginTop:"-20px"}}></div>     
      <div className="quiz-outer">
        <div
          onLoad={this.handleOuterHeight}
          className={"block row questionContainer " + question}
          style={{background: "#098ab4"}}
        >
          <div className="col-md-8 gradient-alt3 question-box">
            <div className="row">
              <div className="col-md-4 image-container">
                <img
                  alt=""
                  className="question-icon"
                  src={questions[this.state.currentQuestion - 1].icon}
                />
              </div>
              <div className="block-headline  my-auto col-md-8">
                <h4 style={{ color: "#ffffff", fontSize:"32px" }}>
                  Question {this.state.currentQuestion} of 6
                </h4>
                <p
                style={{
                  color: "white",
                  fontWeight: 300,
                  fontSize: "24px",
                  lineHeight: "1.2"

                }}
                >{questions[this.state.currentQuestion - 1].question}</p>
              </div>
            </div>
          </div>

          <div aria-live="polite" className="col-md-4 block-content col-sm-offset-3 options-box">
            <div style={{ textAlign: "center" }}>
              <div
                tabIndex="0"
                onKeyDown={this.handleKeySelect}
                onClick={this.handleselect}
                id={"Q" + this.state.currentQuestion + "T"}
                class="abs-horizontal-center quizButton true"
                style={{backgroundColor:"#098ab4"}}
              >
                <div
                  id={"Q" + this.state.currentQuestion + "T"}
                  className="my-auto"
                >
                  True
                </div>
              </div>
              <div
                tabIndex="0"
                onKeyDown={this.handleKeySelect}
                onClick={this.handleselect}
                id={"Q" + this.state.currentQuestion + "F"}
                class="abs-horizontal-center quizButton false"
                style={{backgroundColor:"#098ab4"}}
              >
                <div
                  className="my-auto"
                  id={"Q" + this.state.currentQuestion + "F"}
                >
                  False
                </div>
              </div>
              {this.state.error != "none" ? <div className="quizErrorState abs-horizontal-center"> Please select an answer</div> : null }
              <div
                tabIndex="0"
                onKeyDown={this.handleKeySubmit}
                onClick={this.handleSubmit}
                id={"Q" + this.state.currentQuestion + "S"}
                style={{
                  height: "0px",
                  color: "#D4D4D4",
                  width: "initial",
                  display: "inherit",
                }}
                className="abs-horizontal-center cta question-cta submit-quiz"
              >
                Submit <span aria-hidden="true">&gt;</span>
              </div>
              {this.state.currentQuestion != 1 ? (
                <div
                  tabIndex="0"
                  onKeyDown={this.handleKeyPrevious}
                  onClick={this.handlePrevious}
                  id={"Q" + this.state.currentQuestion + "P"}
                  style={{ color: "#4D4D4d" }}
                  className="previousQuestion-cta"
                >
                  <span aria-hidden="true">&#x3c;</span> Back
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>

        <div
          className={
            "block questionContainer gradient-alt2 answer-container  row " +
            answer
          }
          style={{backgroundColor:"#6b4893"}}
        >
          <div className="col-md-12">
            <div className="row">
              <div className="flex col-md-4 desktop-flex">
                <img
                  alt=""
                  className=""
                  src={questions[this.state.currentQuestion - 1].icon}
                ></img>
              </div>
              <div className="block-headline  my-auto col-md-8">
                <h4 style={{ color: "#ffffff", fontSize:"1.25rem" }}>
                  Question {this.state.currentQuestion} of 6
                </h4>
                <p
                  className="quiz-answer-padding"
                  style={{
                    color: "#ffffff",
                    fontSize: "18px",
                    textAlign: "left",
                    lineHeight: "24px"
                  }}
                >
                  {this.state.currentAnswerState}{" "}
                  {questions[this.state.currentQuestion - 1].answer}
                </p>
                <div>
                  <div className="row">
                    <div
                      tabIndex="0"
                      onKeyDown={this.handleKeyNext}
                      onClick={this.handleNext}
                      id={"Q" + this.state.currentQuestion + "Next"}
                      style={{
                        background: "#ffffff",
                        color: "#333333",
                        marginBottom: "30px",
                      }}
                      class="col-md-6 order-md-2 quizButton"
                    >
                      <div
                        id={"Q" + this.state.currentQuestion + "Next"}
                        className="my-auto"
                      >
                        {questions[this.state.currentQuestion - 1].nextQuestion}
                      </div>
                    </div>

                    <div
                      tabIndex="0"
                      onKeyDown={this.handleKeyPrevious}
                      onClick={this.handlePrevious}
                      id={"Q" + this.state.currentQuestion + "Prev"}
                      style={{
                        background: "#ffffff",
                        color: "#333333",
                        marginBottom: "30px",
                      }}
                      class="mobile-m-t-20 order-md-1 col-md-6 quizButton bottom-button"
                    >
                      <div
                        id={"Q" + this.state.currentQuestion + "Prev"}
                        className="my-auto"
                      >
                          <span aria-hidden="true">&#x3c;</span> Back
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.position == "completed" ? finalScreenRender : null}
        
        </div>
      </div> 
    )
  }
}
